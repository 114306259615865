<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-select
          v-model="selectedItems"
          :items="inputItems"
          :item-text="itemCustomName"
          item-value="id"
          :label="`${$t('selectors.select')} Input Item`"
          clearable
          multiple
          chips
          filled
          @input="onInput"
        ></v-select>
        <v-divider class="mb-5"></v-divider>
      </v-col>
      <v-col cols="12" md="2">
        <!-- Units -->
        <BaseFieldInput
          v-model="outputItem.attributes.units"
          :label="$tc('item.unit', 2)"
          type="number"
          :disabled="disabledForm"
        />
      </v-col>
      <v-col cols="5">
        <!-- Status -->
        <BaseSelector
          v-model="outputItem.attributes.customAttributes.partName"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="partCategories"
          :label="$tc('item.name', 1)"
          :disabled="disabledForm"
        />
      </v-col>
      <v-col cols="5">
        <!-- Status -->
        <BaseSelector
          v-model="outputItem.attributes.name"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="inventoryCategories"
          :label="$t('common.status')"
          :disabled="disabledForm"
        />
      </v-col>
    </v-row>
    <!-- Location -->
    <v-row dense>
      <v-col cols="12" md="6">
        <BaseFieldInput
          v-model="outputItem.attributes.description"
          :label="$t('item.description')"
          :disabled="disabledForm"
        />
      </v-col>
      <v-col>
        <BaseSelector
          v-model="outputItem.attributes.facilityId"
          :label="$t('facility.location')"
          :items="facilities"
          item-text="attributes.name"
          item-value="id"
          :disabled="disabledForm"
        />
      </v-col>
    </v-row>
    <!-- Dimensions -->
    <ItemEditFormMeasurement
      :item="outputItem"
      :disabled="disabledForm"
      :pack-option="true"
    />
    <!-- Comments -->
    <BaseTextareaInput
      v-model="outputItem.attributes.comments"
      :disabled="disabledForm"
      :label="$t('common.comments')"
      rows="3"
    />
  </div>
</template>

<script>
import ItemEditFormMeasurement from '@/components/ItemEditFormMeasurement'
import { INVENTORY_CATEGORIES, PARTS_CATEGORIES } from '@/common/terms'
import { ORGANIZATION_ID } from '@/common/config'
import { mapActions } from 'vuex'
export default {
  components: {
    ItemEditFormMeasurement
  },
  props: {
    outputItem: {
      type: Object,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inventoryCategories: INVENTORY_CATEGORIES,
      partCategories: PARTS_CATEGORIES,
      facilities: [],
      inputItems: [],
      selectedItems: []
    }
  },
  computed: {
    disabledForm() {
      return !this.outputItem.attributes.itemId
    }
  },
  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions({
      filterFacilities: 'facility/filter',
      fetchTask: 'task/fetch'
    }),
    async fetchData() {
      this.isLoding = true
      this.facilities = await this.filterFacilities({
        organizationId: ORGANIZATION_ID()
      })
      const task = await this.fetchTask(this.taskId)
      this.inputItems =
        task.attributes.inputItems.length &&
        task.attributes.inputItems.map(i => i.data)
      this.selectedItems = this.inputItems[0]
      this.isLoding = false
    },
    itemCustomName(item) {
      const { description, name } = item.attributes

      return `${description}  [${this.$t(`inventory.categories.${name}`)}]`
    },
    onInput(itemIds) {
      Object.assign(this.outputItem.attributes, { itemId: itemIds[0] })
    }
  }
}
</script>

<style lang="scss" scoped></style>
