<template>
  <base-form-layout
    :title="`${action} Output`"
    :show-actions="showActions"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <TaskOutputItemEditForm
        v-if="!isLoading"
        :output-item="outputItem"
        :task-id="taskId"
      />
      <v-alert v-if="showAlert" text type="success">
        <v-row align="center">
          <v-col class="grow"> {{ $t('alerts.outputCreated') }} </v-col>
          <v-col class="shrink">
            <div class="d-flex">
              <v-btn
                depressed
                light
                text
                class="mx-1"
                @click="$router.go(-1)"
                >{{ $t('common.close') }}</v-btn
              >
              <v-btn
                depressed
                color="success"
                outlined
                class="mx-1"
                @click="onReset"
                >{{ $t('common.yes') }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-alert>
    </v-form>
  </base-form-layout>
</template>

<script>
import TaskOutputItemEditForm from './components/TaskOutputItemEditForm'
import { TASK_ITEM } from '@/models/task.item'
import { mapActions } from 'vuex'
export default {
  name: 'TaskOutputItemEdit',
  components: {
    TaskOutputItemEditForm
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    outputItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      outputItem: TASK_ITEM('outputItem'),
      confirm: this.$route.query.confirm,
      showActions: true,
      showAlert: false,
      isLoading: false
    }
  },

  computed: {
    action() {
      return this.$t(
        this.confirm
          ? 'actions.confirm'
          : this.outputItemId
          ? 'actions.edit'
          : 'actions.new'
      )
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions({
      fetchOutputItem: 'outputItem/fetch',
      outputItemCreate: 'outputItem/create',
      outputItemUpdate: 'outputItem/update',
      updateTaskState: 'task/state',
      fetchTask: 'task/fetch',
      addNotification: 'notification/add'
    }),
    async fetchData() {
      this.isLoading = true
      if (this.outputItemId)
        this.outputItem = await this.fetchOutputItem({
          taskId: this.taskId,
          taskItemId: this.outputItemId
        })
      this.isLoading = false
    },

    async onSubmit() {
      try {
        const action = this.outputItemId ? 'update' : 'create'
        const fn = p =>
          this.outputItemId
            ? this.outputItemUpdate(p)
            : this.outputItemCreate(p)
        const payload = { taskId: this.taskId, item: this.outputItem }
        await fn(payload)
        if (this.confirm) return this.confirmOutput()

        this.createNotification('200', 'success', 2000, {
          title: `Successfully ${action}d`
        })
        this.showAlert = true
        this.showActions = false
        this.$refs.form.reset()
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.createNotification(status, 'error', 10000, errors)
      }
    },
    createNotification(status, color, time, messages) {
      this.addNotification({
        status,
        color,
        time,
        messages
      })
    },
    async confirmOutput() {
      const task = await this.fetchTask(this.taskId)
      task.attributes.status = 'delivered'
      await this.updateTaskState(task)
      return this.$router.go(-1)
    },
    onReset() {
      this.outputItem = TASK_ITEM('outputItem')
      this.showAlert = false
      this.showActions = true
    }
  }
}
</script>
